import { Injectable } from '@angular/core';
import {
  EmissionDashboardTotals,
  EmissionGuestRecord,
  EmissionGuestRecordUI,
  EmissionsGuest,
  EmissionsGuestResponse,
  TaskRecord,
  TasksResponse,
  TasksTotals,
} from '@core/models';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { DashboardApiService } from './dashboard-api.service';
import { getDataCollectionTemplateName } from '@core/utils';
import { TaskStatusMap } from '@core/constants';
import { DataCollectionTemplateId } from '@core/enums';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  get emissionsGuestTotals$(): Observable<EmissionDashboardTotals> {
    return this.#emissionsGuestTotals$.asObservable();
  }
  get emissionsGuestRecords$(): Observable<EmissionGuestRecordUI[]> {
    return this.#emissionsGuestRecords$.asObservable();
  }
  readonly #emissionsGuestTotals$ = new BehaviorSubject<EmissionDashboardTotals>({
    total: 0,
    transport: 0,
    warehouse: 0,
  });
  readonly #emissionsGuestRecords$ = new BehaviorSubject<EmissionGuestRecordUI[]>([]);

  get taskTotals$(): Observable<TasksTotals> {
    return this.#taskTotals$.asObservable();
  }
  get taskRecords$(): Observable<TaskRecord[]> {
    return this.#taskRecords$.asObservable();
  }
  readonly #taskTotals$ = new BehaviorSubject<TasksTotals>({
    assigned: 0,
    notReported: 0,
    failures: 0,
  });
  readonly #taskRecords$ = new BehaviorSubject<TaskRecord[]>([]);

  constructor(private dashboardApiService: DashboardApiService) {}

  getGuestEmissions(): Observable<EmissionsGuest> {
    return this.dashboardApiService.getGuestEmissions().pipe(
      map((emissions: EmissionsGuestResponse) => {
        const { totals, records } = emissions;

        const roundedTotals: EmissionDashboardTotals = {
          total: parseFloat(totals.total.toFixed(4)) ?? 0,
          transport: parseFloat(totals.transport.toFixed(4)) ?? 0,
          warehouse: parseFloat(totals.warehouse.toFixed(4)) ?? 0,
        };
        const list: EmissionGuestRecordUI[] = records.map((r: EmissionGuestRecord) => ({
          warehouseName: r.warehouseName,
          totalCo2: parseFloat(r.totals.total.toFixed(4)) ?? 0,
          transportCo2: parseFloat(r.totals.transport.toFixed(4)) ?? 0,
          warehouseCo2: parseFloat(r.totals.warehouse.toFixed(4)) ?? 0,
        }));

        return { totals: roundedTotals, list };
      }),
      tap(({ totals, list }) => {
        this.#emissionsGuestTotals$.next(totals);
        this.#emissionsGuestRecords$.next(list);
      }),
    );
  }

  getTasks(): Observable<TasksResponse> {
    return this.dashboardApiService.getTasks().pipe(
      tap((tasks: TasksResponse) => {
        const transformedRecords: TaskRecord[] = tasks.records.map((r: TaskRecord) => ({
          ...r,
          templateId: r.template as DataCollectionTemplateId,
          template: getDataCollectionTemplateName(r.template),
          statusBadge: TaskStatusMap[r.status]
        }));
        this.#taskTotals$.next(tasks.totals);
        this.#taskRecords$.next(transformedRecords);
      }),
    );
  }
}
